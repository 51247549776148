/**
 * Infos
 */

import { wait } from './utils/wait';

// DOM selectors
const $$logo = document.querySelector('.logo-splitted');
const $$body = document.body;
const $$infosWrapper = document.querySelector('.infos-wrapper');

// Options
const DELAY = 7500;

// Handle logo animation states
let timeout = null;

async function init() {
	$$logo.classList.add('is-entering')
	await wait(800);
	setActiveState();

	$$logo.addEventListener('click', () => {
		clearTimeout(timeout);
		timeout = null;

		setActiveState();
	});

	await wait(2000);
	$$logo.classList.add('is-visible')
	$$logo.classList.remove('is-entering')
}

// Alternative version of doing this is available on 'anim-test' branch
async function setActiveState() {
	timeout = setTimeout(async () => {
		$$infosWrapper.classList.remove('is-visible');
		$$logo.classList.remove('has-moved-down');
		await wait(1000);
		$$body.classList.add('has-scaled-down');
	}, DELAY);

	$$body.classList.remove('has-scaled-down');
	$$logo.classList.add('has-moved-down');
	await wait(500);
	$$infosWrapper.classList.add('is-visible');
}

init();
