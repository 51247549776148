/**
 * Video sound
 */

// DOM selectors
const $$video = document.getElementById('video');
const $$soundButton = document.querySelector('.sound-button');
const $$logo = document.querySelector('.logo-splitted');

function toggleMute(p) {
	$$video.muted = !p ? false : !$$video.muted;
	$$soundButton.classList.toggle('is-active');
	$$soundButton.ariaLabel = $$video.muted ? 'Unmute' : 'Mute';
}

$$soundButton.addEventListener('click', toggleMute);

$$logo.addEventListener('click', () => {
	toggleMute(false);
}, { once: true });
