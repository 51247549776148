/**
 * Video timestamp
 */

// DOM selectors
const $$video = document.getElementById('video');
const $$timestamp = document.getElementById('timestamp');

// Handle the video timestamp
let videoCurrentTime = 0;
let videoFormatedCurrentTime = formatTime(videoCurrentTime);
let videoDuration = null;
let videoFormatedDuration = formatTime(videoDuration);

function onVideoTimeUpdate() {
	const newFormattedTime = formatTime($$video?.currentTime);

	if (newFormattedTime !== videoFormatedCurrentTime) {
		videoFormatedCurrentTime = newFormattedTime;
		$$timestamp.innerHTML = videoFormatedCurrentTime;
	}
}

function formatTime(time) {
	const hours = String(Math.floor(time / 3600)).padStart(2, '0');
	const minutes = String(Math.floor(time / 60)).padStart(2, '0');
	const seconds = String(Math.floor(time % 60)).padStart(2, '0');

	return `${hours}:${minutes}:${seconds}`;
}

function onVideoLoadedData() {
	videoDuration = $$video.duration;
	videoFormatedDuration = formatTime(videoDuration);
}

$$video.addEventListener('loadeddata', onVideoLoadedData);
$$video.addEventListener('timeupdate', onVideoTimeUpdate);
