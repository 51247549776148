/**
* Android fullscreen on landscape
**/

if ('orientation' in screen && document.body) {
	window.screen.orientation.onchange = function () {
		if (this.type.startsWith('landscape') && (/Android/i.test(navigator.userAgent))
		) {
			document.body.webkitRequestFullscreen({ navigationUI: 'hide' }); // notch killer
		} else {
			if (document.webkitCancelFullScreen) {
				document.webkitExitFullscreen();
			}
		}
	};
}
