/**
* Touch events for iOS
**/

if (/iP(hone|ad)/.test(window.navigator.userAgent)) {
	var buttons = document.querySelectorAll('button');
	var links = document.querySelectorAll('a');
	var emptyFunction = function() {};

	for (var i = 0; i < buttons.length; i++) {
		buttons[i].addEventListener('touchstart', emptyFunction, false);
	}
	for (var i = 0; i < links.length; i++) {
		links[i].addEventListener('touchstart', emptyFunction, false);
	}
}
